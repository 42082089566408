<template>
<div class="footer_common">
  <footer>
            <div class="container--fluid pa-4">
               <div class="row">
                  <div class="col-md-3 col-sm-3 col-xs-12">
                     <div class="copy_right_text">
                        <p>Copyright © 2021, learncast.in</p>
                     </div>
                  </div>
                  <div class="col-md-6 col-sm-6 col-xs-12">
                     <ul class="middle_footer">
                        <li>
                           <router-link to="/about">About us</router-link>
                        </li>
                        <li>
                           <router-link to="/privacy">Privacy policy</router-link>
                        </li>
                        <li>
                           <router-link to="/terms">Terms of use</router-link>
                        </li>
                        <li>
                           <router-link to="/contact">Contact us</router-link>
                        </li>
                     </ul>
                  </div>
                  <div class="col-md-3 col-sm-3 col-xs-12" style="display:none;">
                     <div class="social_links">
                        <ul>
                           <li>
                              <a href="#">
                                 <v-icon>mdi-facebook</v-icon>
                              </a>
                           </li>
                           <li>
                              <a href="#">
                                 <v-icon>mdi-twitter</v-icon>
                              </a>
                           </li>
                           <li>
                              <a href="#">
                                 <v-icon>mdi-linkedin</v-icon>
                              </a>
                           </li>
                           <li>
                              <a href="#">
                                 <v-icon>mdi-instagram</v-icon>
                              </a>
                           </li>
                        </ul>
                     </div>
                  </div>
               </div>
            </div>
      </footer>
      </div>
</template>
<script>
export default {
  data() {
    return {
      value: ""
    };
  }
};

</script>
<style scoped>

</style>