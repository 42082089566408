import axios from 'axios'
import store from '../store/index'

export default () => {
    const axiosInstance = axios.create({
        baseURL: `${process.env.VUE_APP_API_URL}/api/v1`
    })

    const token = localStorage.getItem('token')
    if (token) {
        axiosInstance.defaults.headers.common.Authorization = `Bearer ${token}`
    }
    //loder//
    axiosInstance.interceptors.request.use(
        config => {
            store.dispatch('toggleAjaxLoader', true)
            return config
        },
        error => {
            Promise.reject(error)
        })
    axiosInstance.interceptors.response.use(
        function(response) {
            store.dispatch('toggleAjaxLoader', false)
            return response
        },
        (error) => {
            store.dispatch('toggleAjaxLoader', false)
            if (error.response.status === 401) {
                localStorage.removeItem('token')
                localStorage.removeItem('user')
                location.reload()
            }
            return Promise.reject(error)
        }
    )

    return axiosInstance
}