import Api from '@/services/Api'

export default {
  getAll(data, params) {
    return Api().get(`notifications/${data}`, {
      params
    })
  },

  updateNotification(id, data) {
    return Api().put(`notifications/${id}`, data)
  },
  createNotification(data) {
    return Api().post('notifications', data)
  },
}
