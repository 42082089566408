import Vue from 'vue'
import VueRouter from 'vue-router'
import NavBar from '@/components/NavBar.vue'


Vue.use(VueRouter)

const routes = [{
        path: '/',
        name: 'Home',
        components: {
            NavBar,
            default: () =>
                import ( /* webpackChunkName: "about" */ '../views/Home.vue')
        }
    },
    {
        path: '/about',
        name: 'About',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/About.vue')
    },
{
        path: '/live-stream',
        name: 'Live-Stram',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/live-stream.vue')
    },
    {
        path: '/privacy',
        name: 'Privacy',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/Privacy.vue')
    },
    {
        path: '/terms',
        name: 'Terms',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/Terms.vue')
    },
    {
        path: '/following',
        name: 'Following',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/Following.vue')
    },
    {
        path: '/edit-profile',
        name: 'Edit-profile',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/Edit-profile.vue')
    },
    {
        path: '/password',
        name: 'Password',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/Auth/Password.vue')
    },
    {
        path: '/contact',
        name: 'Contact',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/Contact.vue')
    },
    {
        path: '/subscriptions',
        name: 'Subscription',
        components: {
            NavBar,
            default: () =>
                import ( /* webpackChunkName: "about" */ '../views/Subscription.vue')
        },
        meta: { requiresAuth: true }
    },
    {
        path: '/liked-videos',
        name: 'LikedVideos',
        components: {
            NavBar,
            default: () =>
                import ( /* webpackChunkName: "about" */ '../views/LikedVideo.vue')
        },
        meta: { requiresAuth: true }
    },
    {
        path: '/notification',
        name: 'Notification',
        components: {
            NavBar,
            default: () =>
                import ( /* webpackChunkName: "about" */ '../views/Notification.vue')
        },
        meta: { requiresAuth: true }
    },
    {
        path: '/signin',
        name: 'SignIn',
        component: () =>
            import ( /* webpackChunkName: "signin" */ '../views/Auth/SignIn.vue'),
        meta: { requiresVisitor: true }
    },
    {
        path: '/forgot-password',
        name: 'SignIn',
        component: () =>
            import ( /* webpackChunkName: "signin" */ '../views/Auth/ForgotPassword.vue'),
        meta: { requiresVisitor: true }
    },
    {
        path: '/signup',
        name: 'SignUp',
        component: () =>
            import ( /* webpackChunkName: "signup" */ '../views/Auth/SignUp.vue'),
        meta: { requiresVisitor: true }
    },
    {
        path: '/trending',
        name: 'Trending',
        components: {
            NavBar,
            default: () =>
                import ( /* webpackChunkName: "trending" */ '../views/Trending.vue')
        }
    },
    {
        path: '/studio',
        components: {
            NavBar,
            default: () =>
                import ( /* webpackChunkName: "dashboard" */ '../views/Studio/Index.vue')
        },
        children: [{
                path: '/',
                name: 'Dashboard',
                component: () =>
                    import (
                        /* webpackChunkName: "dashboard" */
                        '../views/Studio/Dashboard.vue'
                    )
            },
            {
                path: 'videos',
                name: 'Video',
                component: () =>
                    import ( /* webpackChunkName: "video" */ '../views/Studio/Video.vue')
            },
            {
                path: 'details/:id',
                name: 'Detail',
                component: () =>
                    import ( /* webpackChunkName: "video" */ '../views/Studio/Details.vue')
            }
        ],
        meta: { requiresAuth: true }
    },
    {
        path: '/channels/:id',
        components: {
            NavBar,
            default: () =>
                import ( /* webpackChunkName: "dashboard" */ '../views/Channel/Index.vue')
        },
        children: [{
            path: '/',
            name: 'ChannelHome',
            component: () =>
                import (
                    /* webpackChunkName: "dashboard" */
                    '../views/Channel/Home.vue'
                )
        }]
    },
    {
        path: '/watch/:id',
        name: 'Watch',
        components: {
            NavBar,
            default: () =>
                import ( /* webpackChunkName: "video" */ '../views/Watch.vue')
        }
    },
    {
        path: '/history',
        name: 'History',
        components: {
            NavBar,
            default: () =>
                import ( /* webpackChunkName: "video" */ '../views/History.vue')
        },
        meta: { requiresAuth: true }
    },
    {
        path: '/search',
        name: 'Search',
        components: {
            NavBar,
            default: () =>
                import ( /* webpackChunkName: "video" */ '../views/Search.vue')
        }
    },
    {
        path: "/Provide-feedback",
        name: "Provide-feedback",
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/Provide-feedback.vue')
    
      },
      {
        path: '/videos/:id',
        name: 'Videos',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/CategoryVideos.vue')
    },
    {
        path: '/user/activate-account',
        name: 'Activateaccount',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/ActivateAccount.vue')
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    const loggedIn = localStorage.getItem('user')

    if (to.matched.some((record) => record.meta.requiresAuth) && !loggedIn) {
        next('/')
    } else if (
        to.matched.some((record) => record.meta.requiresVisitor) &&
        loggedIn
    ) {
        next('/')
    } else {
        next()
    }
})

export default router