<template>
  <v-dialog
    v-model="dialog"
    persistent
    transition="fab-transition"
    max-width="800"
    id="settings"
  >
    <v-card tile>
      <div class="d-flex justify-space-between mb-5" id="modal-header">
        <v-card-title class="py-3">Edit Profile</v-card-title>

        <v-btn icon text @click="closeModal">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>

      <v-card-text>
        <v-row>
          <v-col
            order="last"
            order-sm="last"
            order-md="first"
            order-lg="first"
            order-xl="first"
            cols="12"
            sm="12"
            md="8"
            lg="8"
            class="pt-0"
          >
            <h3 class="mb-6 mt-0 pt-0">Personal Information</h3>
            <ValidationObserver
              ref="personalInfoForm"
              v-slot="{ handleSubmit, reset }"
            >
              <form
                @submit.prevent="handleSubmit(submit)"
                @reset.prevent="reset"
              >
               <ValidationProvider
                  v-slot="{ errors }"
                  name="Name"
                  rules="required"
                >
                  <v-text-field
                    v-model="formData.name"
                    :error-messages="errors"
                   label="Name"
                   outlined
                   dense
                  ></v-text-field>
                </ValidationProvider>
               
               
                
                <ValidationProvider
                  v-slot="{ errors }"
                  name="email"
                  rules="required|email"
                >
                  <v-text-field
                    v-model="formData.email"
                    :error-messages="errors"
                    label="Email"
                   outlined
                   dense
                  ></v-text-field>
                </ValidationProvider>

                 <ValidationProvider
                  v-slot="{ errors }"
                  name="Phone"
                  rules="required"
                >
                  <v-text-field
                    v-model="formData.phone"
                    :error-messages="errors"
                     label="Phone"
                   outlined
                   dense
                  ></v-text-field>
                </ValidationProvider>

          <v-menu
        v-model="menu2"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="auto"
        
      >
       
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="formData.date"
            label="Select date"
            prepend-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            v-on="on"
            outlined
            dense
            :error-messages="errors"

          ></v-text-field>
        </template>
        <v-date-picker
          v-model="formData.date"
          @input="menu2 = false"
                      :error-messages="errors"
     :allowed-dates="(date) => date <= new Date().toISOString().substr(0, 10)"

        ></v-date-picker>
      </v-menu>
          
                <ValidationProvider
                  v-slot="{ errors }"
                  name="Channel Name"
                  rules="required|min:3"
                >
                  <v-text-field
                    v-model="formData.channelName"
                    :error-messages="errors"
                    label="Channel Name"
                   
                   outlined
                   dense
                  ></v-text-field>
                </ValidationProvider>
                
                <ValidationProvider
                >
                  <v-select
          :items="classnames"
          v-model="formData.className"
          label="Class"
          dense
          outlined
        ></v-select>
                </ValidationProvider>
                
                <ValidationProvider
                  v-slot="{ errors }"
                  name="School Name"
                  rules="required|min:3"
                >
                  <v-text-field
                    v-model="formData.schoolName"
                    :error-messages="errors"
                    label="School Name"
                    outlined
                   dense
                  ></v-text-field>
                </ValidationProvider>
                 <ValidationProvider  v-slot="{ errors }"
                      name="location"
                  rules="required"  class="auto_location">
          <VueGoogleAutocomplete
            outlined
            dense
            id="map"
            v-on:placechanged="getAddressData"
    ref="location" 
       placeholder="Location" 
    v-model="formData.location" 
            append-icon="mdi-map-marker"
            :error-messages="errors"
          ></VueGoogleAutocomplete>
      </ValidationProvider>
  <ValidationProvider
                           v-slot="{ errors }"
                      name="bio"
                  rules="required" 
                        >
                    <v-textarea
          v-model="formData.biograph"
          outlined
          auto-grow
          row-height="20"
          name="input-7-4"
          label="Bio" hide-details=true
          :error-messages="errors"
          value=""
        ></v-textarea>
         </ValidationProvider>
               <div class="bottom_buttons_nw">
                  <v-btn
                  :loading="loading.personalInfo"
                  type="submit"
                  class="primary "
                  depressed
                  >Submit</v-btn
                >
               </div>
              </form>
            </ValidationObserver>

 
           
          </v-col>
          <v-col
            order-sm="1"
            cols="12"
            sm="12"
            md="4"
            lg="4"
            class="text-center position_rel_edit" 
          >
            
            <div class="photo_add_">
              
              </div>

              <!-- {{$store.getters.currentUser.photoUrl}}

              {{imgDataUrl}} -->
            <my-upload
              field="avatar" 
              method="PUT"
              :headers="headers"
              @crop-success="cropSuccess"
              @crop-upload-success="cropUploadSuccess"
              @crop-upload-fail="cropUploadFail"
              v-model="show"
              :url="url"
              :width="150"
              :height="140"
              :noRotate="false"
              img-format="jpg"
              langType="en"
            ></my-upload>
           
            <v-responsive
              width="230"
              class="mx-auto"
              style="border-radius: 50%;"
            > 
              <div v-if="$store.getters.currentUser.photoUrl == ''" class="px-12"      
              id="settings-image-placeholder" >
                <v-icon @click="toggleShow">mdi-image-plus</v-icon>
              </div>
              <v-img v-else height="220" :src="imgDataUrl"></v-img>
            </v-responsive>
                        <b-link :href="imagePreview" target="_blank">
                          <b-img thumbnail :src="imagePreview" rounded :alt="formData['full_name']"
                                 @error="$event.target.src=defaultImage"></b-img>
                        </b-link>
            <!-- <v-icon>mdi-image-plus</v-icon> -->

            <!-- <button class="btn btn-dark" @click="handleImage">Edit Profile</button> -->
<!-- <input type="file" @change="onFileChange" /> -->
            <!-- 

            <div id="preview">
              <img v-if="url" :src="url" />
            </div> -->

            <v-btn v-if="$store.getters.currentUser.photoUrl" text @click="toggleShow" depressed style="background:#ff8100; color:white">Change the Photo</v-btn>

          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
     <!-- <image-cropper-modal ref="imageCropperModal" @cropped="handleImageCropped"></image-cropper-modal> -->
  </v-dialog>
  
</template>

<script>
import AuthenticationService from '@/services/AuthenticationService'
import myUpload from 'vue-image-crop-upload'
import VueGoogleAutocomplete from 'vue-google-autocomplete'
// import ImageCropperModal from "../components/ImageCropperModal";

export default {
  name: 'SettingsModal',
  props: ['openDialog'],
  data: function() {
    return {
      menu2:'',
      showImagePreview:'',
      imagePreview:`${this.$store.getters.currentUser.photoUrl}`,
      croppedImageBlob:'',
      showCurrentPassword: false,
      showNewPassword: false,
      valid: false,
      interval: {},
      value: 0,
      errors:null,
      show: false,
      rules: [
        (value) =>
          !value ||
          value.size > 0 ||
          'Video size should be 2 MB or more!'
      ],
     classnames: ['First Standared', 'Second Standared', 'Third Standared', 'Fourth Standared'],
     //date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),

      categories: ['People', 'Technology', 'Fashion'],
      visibilty: ['Public', 'Private'],
      formData: {
        name: this.$store.getters.currentUser? this.$store.getters.currentUser.name: '',
        channelName: this.$store.getters.currentUser? this.$store.getters.currentUser.channelName : '',
        schoolName: this.$store.getters.currentUser? this.$store.getters.currentUser.schoolName: '',
        className: this.$store.getters.currentUser? this.$store.getters.currentUser.className: '',
        email: this.$store.getters.currentUser? this.$store.getters.currentUser.email: '',
        phone: this.$store.getters.currentUser? this.$store.getters.currentUser.phone: '',
        location: this.$store.getters.currentUser? this.$store.getters.currentUser.location: '',
        biograph: this.$store.getters.currentUser? this.$store.getters.currentUser.biograph: '',
        date: this.$store.getters.currentUser? this.$store.getters.currentUser.dateofBirth: '',
        currentPassword: '',
        newPassword: ''
      },
      loading: {
        personalInfo: false,
        password: false
      },
      // imgDataUrl: `${process.env.VUE_APP_API_URL}/uploads/avatars/${this.$store.getters.currentUser.photoUrl}`,
      imgDataUrl: `${this.$store.getters.currentUser.photoUrl}`,
      url: `${process.env.VUE_APP_API_URL}/api/v1/auth/avatar`,
      headers: { Authorization: `Bearer ${this.$store.getters.getToken}` }
    }
  },
  computed: {
    dialog() {
      return this.openDialog
    }
  },
  methods: {
    onFileChange(e) {
      const file = e.target.files[0];

      
      console.log("files dataaa", file)

      this.url = URL.createObjectURL(file);

      console.log("this.url", this.url)

    },
      getAddressData: function (addressData, placeResultData,id) {          
        console.log(addressData)
            this.formData.location = placeResultData.formatted_address;
            this.loclat = addressData.latitude
            this.loclng = addressData.longitude
              this.id = id
            this.formData.loc_lat_lng = this.loclat+','+this.loclng
            localStorage.setItem('loc_lat_lng', this.loclat+','+this.loclng)
            localStorage.setItem('auto_lat', this.loclat)
            localStorage.setItem('auto_lng', this.loclng)
      },
    async submit() {
     
      if (this.loading.personalInfo) return
      this.loading.personalInfo = true
      
      const user = await AuthenticationService.updateUserDetails({
        channelName: this.formData.channelName,
        name: this.formData.name,
        schoolName: this.formData.schoolName,
        className: this.formData.className,
        email: this.formData.email,
        phone: this.formData.phone,
        location: this.formData.location,
        biograph: this.formData.biograph,
        dateofBirth: this.formData.date,
        currentPassword: '',
        newPassword: ''

      })
      
        .catch((err) => {
          this.loading.personalInfo = false
          const errors = err.response.data.error

          this.$refs.personalInfoForm.setErrors({
            'email': errors.find((error) => {
              return error.field === 'email'
            })
              ? ['This email is already taken']
              : null,
            'Channel Name': errors.find((error) => {
              return error.field === 'channelName'
            })
              ? ['This channel name is already taken']
              : null
          })
        })
        .finally(() => (this.loading.personalInfo = false))

      if (!user) return
      if (
        this.formData.channelName !==
          this.$store.getters.currentUser.channelName ||
        this.formData.email !== this.$store.getters.currentUser.email
      ) {
        this.$store.dispatch('signOut')
        this.$router.push('/signin')
      }
      else
      {
             this.$toasted.show("profile updated succesfully", {
                theme: "bubble",
                position: "bottom-right",
                type: "success",
                duration: 5000
              });
      }

      this.closeModal()
    },
    async submitPassword() {
      if (this.loading.password) return

      this.loading.password = true

      const user = await AuthenticationService.updatePassword({
        currentPassword: this.formData.currentPassword,
        newPassword: this.formData.newPassword
      })
        .catch((err) => {
          this.loading.password = false
          const errors = err.response.data.error

          this.$refs.passwordForm.setErrors({
            'Current password': errors.find((error) => {
              return error.field === 'currentPassword'
            })
              ? ['Current password is incorrect']
              : null,
            'New password': errors.find((error) => {
              return error.field === 'newPassword'
            })
              ? errors.find((error) => {
                  return error.field === 'newPassword'
                }).message
              : null
          })
        })
        .finally(() => (this.loading.password = false))
      if (!user) return

      this.formData.currentPassword = ''
      this.formData.newPassword = ''
      this.closeModal()

      this.$store.dispatch('signOut')
      this.$router.push('/signin')
    },
    closeModal() {
      this.$emit('closeDialog')
    },

    toggleShow() {
      this.show = !this.show
    },
    cropSuccess(imgDataUrl, field) {
      // console.log('-------- crop success --------')
      console.log(field)
      // console.log(imgDataUrl)
      this.imgDataUrl = imgDataUrl
      // console.log(this.imgDataUrl)
      // console.log(field)
    },
    cropUploadSuccess(jsonData, field) {
      // console.log('-------- upload success --------')
      const user = this.$store.getters.currentUser
      user.photoUrl = jsonData.data
      this.$store.dispatch('signin', user)
      console.log('field: ' + field)
    },

    cropUploadFail(status, field) {
      console.log('-------- upload fail --------')
      console.log(status)
      console.log('field: ' + field)
    },
    handleImage(e) {
      console.log("image daaaa",e)
      const image = e.target.files[0]
      const reader = new FileReader()
      reader.readAsDataURL(image)
      reader.onload = e => {
        this.previewImage = e.target.result
        this.update()
      }

     
    },
    handleImageCropped(d){
      this.showImagePreview = true;
      this.imagePreview = d.preview;
      this.croppedImageBlob = d.blob;

      this.update()
    },
  },
  components: {
    myUpload,
    VueGoogleAutocomplete,
    // ImageCropperModal
  },
   mounted (){
   },
}
</script>

<style lang="scss">
#modal-header {
  border-bottom: 1px solid rgb(238, 232, 232);
}

#settings-image-placeholder {
  padding-top: 7em;
  padding-bottom: 7em;
  border: 2px dashed rgb(209, 209, 209);
  border-radius: 50%;
}

@media (max-width: 700px) {
  .vue-image-crop-upload {
    .vicp-wrap {
      width: 95%;
      height: 550px;
      .vicp-step2 .vicp-crop {
        .vicp-crop-left {
          float: none;

          .vicp-range {
            margin: auto;
            margin-top: 30px;
          }

          & * {
            margin-left: auto;
            margin-right: auto;
          }
        }
        .vicp-crop-right {
          float: none;
          margin-top: 30px;
          .vicp-preview .vicp-preview-item {
            display: inline-block;
            float: none;
          }
        }
      }
    }
  }
}
</style>
