var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[(_vm.$route.name != 'SignIn' && _vm.$route.name != 'SignUp' )?_c('Header'):_vm._e(),_c('router-view',{attrs:{"name":"NavBar"}}),_c('v-content',{class:{
        'content-bg': ![
          'SignIn',
          'SignUp',
          'Dashboard',
          'Video',
          'Detail'
        ].includes(this.$route.name)
          ? true
          : false
      }},[_c('router-view',{key:_vm.$route.path})],1),(_vm.$route.name != 'SignIn' && _vm.$route.name != 'SignUp')?_c('Footer'):_vm._e(),(_vm.isLoading)?_c('BlockUI',{attrs:{"message":_vm.msg,"url":_vm.loadingImage}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }