import Api from '@/services/Api'

export default {
  getAll() {
    return Api().get(`categories`)
  },

  getCategoryById(categoryId)
  {
    return Api().get(`categories/${categoryId}`)
  }
}
