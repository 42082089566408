<template>
<div class="main_nave_section">
  <nav id="navbar" class="header_common_section">
    <v-app-bar class="white" flat app clipped-left>
      <v-app-bar-nav-icon @click="drawer = !drawer" class="top_menu_icon"></v-app-bar-nav-icon>
      <v-toolbar-title class="branding_logo">
        <router-link to="/" class="black--text" style="text-decoration: none"
          >  <img src="../assets/images/login-logo.svg" alt="Exsport"/></router-link
        ></v-toolbar-title
      >
      <v-spacer></v-spacer>
      <v-btn
      class="mx-2 mobile_search_icon" v-on:click="isHidden = !isHidden"
      fab
      dark
      small
      color="primary"
    >
      <v-icon dark>
      mdi-magnify
      </v-icon>
    </v-btn>
  <v-autocomplete
        v-model="searchText"
        :items="videos"
        :loading="isLoading"
        :search-input.sync="search"
        chips
        clearable
        hide-details
        item-text="title"
        label="Search videos"
          @change="handleOnSelect()"
       solo
      >
        
        <template v-slot:no-data>
          <v-list-item>
            <v-list-item-title>
              Search for your favorite
              <strong>videos</strong>
            </v-list-item-title>
          </v-list-item>
        </template>
        <template v-slot:selection="{ attr,  item, selected }">
          <v-chip
            v-bind="attr"
            :input-value="selected"
              :click="search" 
            color="blue-grey"
            class="white--text"
                 >
            <span v-text="item.title"></span>
          </v-chip>
        </template>
       
      </v-autocomplete>
      <v-text-field v-if="!isHidden" class="mobile_position_fixed"
      append-icon="mdi-magnify"
        placeholder="Search"
        flat
        hide-details
        outlined
        dense
        v-model="searchText"
        @click:append="search"
      > </v-text-field>
      
   
        <div class="voice_rec_icon">
         <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on" @click="showVoice"> <v-icon size="25">mdi-microphone-outline</v-icon></v-btn>
        </template>
        <span>Search with your voice</span>
      </v-tooltip>
     </div>
      <v-spacer></v-spacer>
      <v-menu offsetY>
 <div class="text-center">
    <v-dialog
      v-model="dialog"
      width="500"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="red lighten-2"
          dark
          v-bind="attrs"
          v-on="on"
        >
         <v-icon size="25">mdi-video-plus</v-icon>
        </v-btn>
      </template>

      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Privacy Policy
        </v-card-title>

        <v-card-text>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="dialog = false"
          >
            I accept
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>

        <v-list>
          <v-list-item router to="/studio">
            <v-list-item-icon class="mr-3"
              ><v-icon>mdi-play-box-outline</v-icon></v-list-item-icon
            >
     
            <v-list-item-title>Upload video</v-list-item-title>
          </v-list-item>
           <v-list-item>
            <v-list-item-icon class="mr-3"
              ><v-icon>mdi-access-point</v-icon></v-list-item-icon
            >
            <v-list-item-title>Go live</v-list-item-title>
          </v-list-item> 
        </v-list>
      </v-menu>
     <div class="before_login_header">
  <ul>
   <li class="mr-5 hidden_mobile"><router-link to="/"><v-icon>mdi-home-outline</v-icon>Home</router-link></li>
    <li class="mr-5 hidden_mobile"><router-link to="/"><v-icon>mdi-apps</v-icon>Categories</router-link>
    <div class="header_menu_list">
      <ul>                    
        <li v-for="item in categories" :key="item._id" @click="$router.push('/videos/'+item._id).catch(() => {})"> <span class="left_side_icons">
          <!-- :src="`${getUrl}/uploads/category/${item.image}`" -->
          <img
                    :src="`${getUrl}/uploads/category/${item.image}`"
                    :alt="`${item.title} avatar`"
                  />
          </span>{{item.title}}</li>
      
      </ul>
    </div>
    </li>
   <!-- <li class="mr-5 hidden_mobile"><router-link to="/contact"><v-icon>mdi-information-outline</v-icon>Help</router-link></li>
    -->
        <li class="mr-5 hidden_mobile" style="cursor:pointer"  @click="dialog = true" v-if="isAuthenticated && currentUser"><v-icon>mdi-cloud-upload
</v-icon>Upload Video</li>
      <li class="custome_not" v-if="isAuthenticated && currentUser" >  
         <v-menu offset-y max-width="400" class="notifications_top_list">
          <template v-slot:activator="{ on }">
            <v-btn
              dark
              icon
              v-on="on"
            >
              <v-icon color="primary">notifications</v-icon>
            </v-btn>
          </template>
          <v-card>
            <v-list dense>
              <h4 class="not_title">Notifications</h4>
              <v-divider></v-divider>
              <v-list-tile
                v-for="notification in notifications"
                :key="`notification-key-${notification._id}`"
              >
                <v-list-tile-title>
              <div class="notfication_list">
                <a href="">         
                           <v-row>
                  <div class="col-2">
                    <div class="left_profile_img">P</div>
                  </div>
                  <div class="col-7"  @click="readNotifications(notification._id)">
                    <p>{{notification.text}}</p>
                    <label>{{ dateFormatter(notification.createdAt) }}</label>
                    </div>
                  <div class="col-2">
                    <div class="right_video_img">
                       <img src="../assets/images/new_video_bg.jpg" alt="Exsport"/>
                    </div>
                  </div>
                </v-row>
                </a>
            </div>
                </v-list-tile-title>
              </v-list-tile>
            </v-list>
          </v-card>
        </v-menu>
     <span class="not_counts">{{ notifications.length }}</span></li> 
  </ul>
 <!-- {{currentUser.photoUrl}} -->
     </div>
      <v-btn tile outlined color="orange" class="change_the_color" v-if="!$store.getters.isAuthenticated" router to="/signin" >
        <v-icon style="display: none;" left size="26">mdi-account-circle</v-icon> Sign in
      </v-btn>
      
     
      <v-menu offset-y left v-else>
        
        <template v-slot:activator="{ on }">
          <!-- {{currentUser.photoUrl}} -->
          <v-btn small color="red" depressed fab v-on="on" class="white--text">
            <v-avatar v-if="currentUser.photoUrl">
              <img
                :src="`${currentUser.photoUrl}`"
                :alt="`${currentUser.channelName}`"
              />
            </v-avatar>
            <template v-else>
              <span class="headline">
                {{ currentUser.channelName.split('')[0].toUpperCase() }}
              </span>
            </template>
          </v-btn>
        </template>
        <v-card class="after_loginsection">
          <v-list>
            <v-list-item>
              <v-list-item-avatar>
                <v-avatar v-if="currentUser.photoUrl !== 'no-photo.jpg' && currentUser.photoUrl !== ''">
                  <img
                    :src="`${currentUser.photoUrl}`"
                    :alt="`${currentUser.channelName.split('')[0].toUpperCase()} avatar`"
                  />
                </v-avatar>
                <template v-else>
                  <v-avatar color="red">
                    <span class="white--text headline ">
                      {{
                        currentUser.channelName.split('')[0].toUpperCase()
                      }}</span
                    >
                  </v-avatar>
                </template>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title class="text-capitalize">  
                  {{currentUser.channelName}}
                </v-list-item-title>
                <v-list-item-subtitle>{{
                  currentUser.email
                }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>

          <v-divider></v-divider>

          <v-list>
             
               <v-list-item router to="/following">
              <v-list-item-icon>
                <v-icon>mdi-account-switch</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Following</v-list-item-title>
            </v-list-item>
                <v-list-item router to="/liked-videos">
              <v-list-item-icon>
                <v-icon>mdi-hand-pointing-right</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Liked videos</v-list-item-title>
            </v-list-item>
                <v-list-item router to="/history">
              <v-list-item-icon>
                <v-icon>mdi-calendar-clock</v-icon>
              </v-list-item-icon>
              <v-list-item-title>History</v-list-item-title>
            </v-list-item>
              <v-list-item router to="/studio/videos">
              <v-list-item-icon>
                <v-icon>mdi-youtube-studio</v-icon>
              </v-list-item-icon>
              <v-list-item-title>My Videos</v-list-item-title>
            </v-list-item>
             <div class="border_bottom"></div>
               <v-list-item >
              <v-list-item-icon>
                <v-icon>mdi-account-edit</v-icon>
              </v-list-item-icon>
              <v-list-item-title  style="cursor:pointer;"  @click="settingsMoal()">Edit profile</v-list-item-title>
            </v-list-item>
               <v-list-item router to="/password">
              <v-list-item-icon>
                <v-icon>mdi-lock-reset</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Change password</v-list-item-title>
            </v-list-item>
               <v-list-item  @click="showFeedback" >
              <v-list-item-icon>
                <v-icon>mdi-message-draw</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Send feedback</v-list-item-title>
            </v-list-item>
            <v-list-item
              router
              :to="`/channels/${$store.getters.currentUser._id}`"
            >
              <v-list-item-icon>
                <v-icon>mdi-account-box</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Your channel</v-list-item-title>
            </v-list-item>
            <v-list-item router to="/studio">
              <v-list-item-icon>
                <v-icon>mdi-youtube-studio</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Subscribers</v-list-item-title>
            </v-list-item>
          
            <v-list-item @click="signOut">
              <v-list-item-icon>
                <v-icon>mdi-login-variant</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Logout</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-card>
      </v-menu>
    </v-app-bar>
    
    <v-navigation-drawer 
      v-model="drawer"
      app
      :clipped="$route.name !== 'Watch'"
      :temporary="$route.name === 'Watch'"
      id="nav"  class="mobile_nav_menusection"
    >
      <div tag="div" class="v-navigation-drawer__content" v-bar>
        <v-list dense nav class="py-0" tag="div">
          <v-list-item
            :class="{
              'hidden-lg-and-up': $route.name === 'Watch' ? false : true
            }"
          >
            <v-app-bar-nav-icon
              @click="drawer = !drawer"
              class="mr-5"
            ></v-app-bar-nav-icon>
            <v-toolbar-title class="font-weight-bold">
              <img src="../assets/images/login-logo.svg" alt="Exsport"/>
            </v-toolbar-title>
          </v-list-item>
          <v-divider class="hidden-lg-and-up"></v-divider>
          <div v-for="parentItem in items" :key="parentItem.header">
            <v-subheader
              v-if="parentItem.header"
              class="pl-3 py-4 subtitle-1 font-weight-bold text-uppercase"
              >{{ parentItem.header }}</v-subheader
            >
            <v-list-item
              v-for="(item, i) in parentItem.header === 'Subscriptions'
                ? items[2].pages.slice(0, channelLength)
                : parentItem.pages"
              :key="item.title"
              class="mb-0"
              :to="
                parentItem.header === 'Subscriptions'
                  ? '/channels/' + item.channelId._id
                  : item.link
              "
              exact
              active-class="active-item"
            >
              <v-list-item-icon v-if="parentItem.header !== 'Subscriptions'">
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-avatar v-else class="mr-5">
                {{ i }}
                
                <v-avatar
                  v-if="
                    item.channelId.photoUrl !== 'no-photo.jpg' && item.channelId
                  "
                >
                  <img
                  
                    :src="
                      `${item.channelId.photoUrl}`
                    "
                    :alt="`${item.channelId.channelName} avatar`"
                  />
                </v-avatar>
                <template v-else>
                  <v-avatar color="red">
                    <span class="white--text headline ">
                      {{
                        item.channelId.channelName.split('')[0].toUpperCase()
                      }}</span
                    >
                  </v-avatar>
                </template>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title class=" font-weight-medium subtitle-2">{{
                  parentItem.header === 'Subscriptions'
                    ? item.channelId.channelName
                    : item.title
                }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-btn
              id="showBtn"
              @click="moreChannels"
              v-if="
                parentItem.header === 'Subscriptions' &&
                  isAuthenticated &&
                  items[2].length > 0
              "
              block
              text
              class="text-none"
            >
              <v-icon>{{
                channelLength === 3 ? 'mdi-chevron-down' : 'mdi-chevron-up'
              }}</v-icon>
              {{
                channelLength === 3
                  ? `Show ${items[2].pages.length - 3} more `
                  : 'Show less'
              }}</v-btn
            >

            <v-divider
              v-if="parentItem.header !== false"
              class="mt-2 mb-2"
            ></v-divider>
          </div>

        
        </v-list>
      </div>
    </v-navigation-drawer>
  </nav>
     <modal name="show-voice-modal">
        <div class="google_voice">
          <p>Listening ...</p>
          <div class="try_say">
            <label>Try saying something</label>
        <template>
          <v-btn
      class="mx-2"
      fab
      dark
      large
      color="orange"
    ><v-icon size="25"> mdi-microphone</v-icon></v-btn>
        </template>
    
          </div>
        </div>
    </modal>


     <modal name="show-send-feedback" :adaptive="true"
    width="100%"
    :maxWidth="600"
    height="auto"
    :scrollable="true"
   >
        <div class="google_voice show_send_feedback">
          <h3>Send Feedback</h3>
          <p>Do you have a suggestion or found some bug? <br/>let us know in the field below</p>
        <template>
        <v-textarea
          outlined
          name="input-7-4"
          label="Describe your feedback here!"
          value=""
        ></v-textarea>
    <div class="send_feed_abck_center">
       <v-btn @click="clear"  color="block"
          dark>
      clear
    </v-btn>
    <v-btn
      class="mr-4"  color="orange"
          dark
      @click="submit"
    >
      submit
    </v-btn>
    </div>


        </template>
    
        </div>
    </modal>

     <settings-modal
      :open-dialog="settingsDialog"
      v-on:closeDialog="settingsDialog = false"
    />

          
    <upload-video-modal
      :open-dialog="dialog"
      v-on:closeDialog="dialog = false"
    />
  </div>




</template>
<script>
import { mapGetters } from 'vuex'
import SubscriptionService from '@/services/SubscriptionService'
import HistoryService from '@/services/HistoryService'
import SettingsModal from '@/components/SettingsModal'
import CategoryService from "@/services/CategoryService";
import NotificationService from "@/services/NotificationService";
import FeedbackService from "@/services/FeedbackService";
import UploadVideoModal from '@/components/UploadVideoModal';

// import into project
import Vue from 'vue';
import moment from 'moment';
import SearchService from '@/services/SearchService'
    //  import VideoService from '@/services/VideoService'

import vmodal from 'vue-js-modal';
Vue.use(vmodal);
 
// Tell Vue to install the plugin.
//Vue.use(VuejsDialog);

export default {
  name: 'NaveBar',
  data: () => ({
     
    livePaper: false,
    dialog:false,
    drawer: false,
    settingsDialog: false,
    categories: [],
    isLoading: false,
        videos: [],
        model: null,
        search: null,
        tab: null,
        page:1,
    notifications:[],
    isHidden: true,
    items: [
      {
        header: null,
        pages: [
          { title: 'Home', link: '/', icon: 'mdi-home' },
          { title: 'Trending', link: '/trending', icon: 'mdi-fire' },
          {
            title: 'Subscriptions',
            link: '/subscriptions',
            icon: 'mdi-youtube-subscription'
          }
        ]
      },
      {
        header: null,
        pages: [
          // {
          //   title: 'Library',
          //   link: '#l',
          //   icon: 'mdi-play-box-multiple'
          // },
          {
            title: 'History',
            link: '/history',
            icon: 'mdi-history'
          },
          // {
          //   title: 'Your videos',
          //   link: '#yv',
          //   icon: 'mdi-play-box-outline'
          // },

          // {
          //   title: 'Watch later',
          //   link: '#wl',
          //   icon: 'mdi-clock'
          // },

          {
            title: 'Liked videos',
            link: '/liked-videos',
            icon: 'mdi-thumb-up'
          }
        ]
      },
      {
        header: 'Subscriptions',
        pages: [
          // {
          //   title: 'Traversy Media',
          //   link: '#tm',
          //   icon: 'mdi-badge-account'
          // },
          // {
          //   title: 'The New Boston',
          //   link: '#tn',
          //   icon: 'mdi-badge-account'
          // },
          // {
          //   title: 'Net Ninija',
          //   link: '#nn',
          //   icon: 'mdi-badge-account'
          // },
          // {
          //   title: 'Chris Hawks',
          //   link: '#ch',
          //   icon: 'mdi-badge-account'
          // }
        ]
      },
      {
        header: 'MORE FROM VUETUBE',
        pages: [
          {
            title: 'VueTube Premium',
            link: '#vp',
            icon: 'mdi-youtube'
          },
          {
            title: 'Gaming',
            link: '#g',
            icon: 'mdi-youtube-gaming'
          },
          {
            title: 'Live',
            link: '#li',
            icon: 'mdi-access-point'
          }
        ]
      },
      {
        header: null,
        pages: [
          {
            title: 'Setting',
            link: '#sg',
            icon: 'mdi-cog'
          },
          {
            title: 'Report history',
            link: '#rh',
            icon: 'mdi-flag'
          },
          {
            title: 'Help',
            link: '#hp',
            icon: 'mdi-help-circle'
          },
          {
            title: 'Send feedback',
            link: '#f',
            icon: 'mdi-message-alert'
          }
        ]
      }
    ],
    //links: [
     // { text: 'About', link: '/about' },
      //{ text: 'Press', link: '#' },
     // { text: 'Copyrignt', link: '#' },
     // { text: 'Contact us', link: '/contact' },
      //{ text: 'Creators', link: '#' },
     // { text: 'Advertise', link: '#' },
      //{ text: 'Developers', link: '#' },
      //{ text: 'Terms', link: '/terms' },
     // { text: 'Privacy', link: '/privacy' },
      //{ text: 'Policy & Safety', link: '#' },
      //{ text: 'Test new features', link: '#' }
   // ],
    channelLength: 0,
    searchText: '',
    notificationCount:'',
    feedback:'',
    // user: null
  }),

  computed: {
    ...mapGetters(['currentUser', 'getUrl', 'isAuthenticated'])
  },

  methods: {
     dateFormatter(date) {
      return moment(date).fromNow();
    },
    showVoice(){
     // alert('test')
     this.$modal.show('show-voice-modal')
     
    },
   handleOnSelect() {
      if (!this.searchText) return
      // console.log(this.searchText == this.$route.query['search-query'])
      if (this.searchText == this.$route.query['search-query']) return
      // this.searchText = this.$route.query['search-query']
      const data = {
        type: 'search',
        searchText: this.searchText
      }

      if (this.isAuthenticated)
     HistoryService.createHistory(data).catch((err) =>
          console.log(err)
        )

      this.$router.push({
        name: 'Search',
        query: { 'search-query': this.searchText }
        
      })
        console.log("selected: ", this.searchText);
      },
    showFeedback(){
      this.$router.push('/contact')
     // alert('test')
     //this.$modal.show('show-send-feedback')

    },

        async getVideoList(){
         
             this.errored = false
      if (!this.loaded) {
        this.loading = true
      }
      const fileterresults = await SearchService.search(this.page, {
        text: this.searchText
      })
        .catch((err) => {
          console.log(err)
          this.errored = true
        })
        .finally(() => {
          this.loading = false
        })

      if (!fileterresults) return
      if (fileterresults.data.data.length) {
        console.log(fileterresults.data.data)
        this.results = fileterresults.data.data;
      } 
      },
  
      async submit() {
          if (this.feedback === '') return

      this.loading = true
      const feedbacks = await FeedbackService.createFeedback({
        text: this.feedback,
        userId: this.$store.getters.currentUser
      })
        .catch((err) => {
          console.log(err)
        })
        .finally(() => {
                this.feedback = ''
     this.$modal.hide('show-send-feedback')

            this.$toasted.show("feedback submitted succesfully", {
                theme: "bubble",
                position: "bottom-right",
                type: "success",
                duration: 5000
              });
        })
      if (!feedbacks) return

    },

     clear(){
     // alert('test')
     this.$modal.hide('show-send-feedback')
    },
     
    async search() {
      if (!this.searchText) return
      // console.log(this.searchText == this.$route.query['search-query'])
      if (this.searchText == this.$route.query['search-query']) return
      // this.searchText = this.$route.query['search-query']
      const data = {
        type: 'search',
        searchText: this.searchText
      }

      if (this.isAuthenticated)
        await HistoryService.createHistory(data).catch((err) =>
          console.log(err)
        )

      this.$router.push({
        name: 'Search',
        query: { 'search-query': this.searchText }
        
      })
    },
    async getCategories() {
      this.loading = true
      const categories = await CategoryService.getAll()
        .catch((err) => {
          console.log(err);
        })
        .finally(() => (this.categoryLoading = false));

      this.categoriesTitles = categories.data.data.map((category) => {
        return category.title;
      });
      this.categories = categories.data.data;

    },
     async getNotification() {
        if (!this.isAuthenticated) return
      this.loading = true
      const notification = await NotificationService.getAll('is_read',{userId: this.$store.getters.currentUser._id})
        .catch((err) => {
          console.log(err);
        })
         .finally(() => (this.loading = false));
      // console.log(notification.data.data)
      this.notifications = notification.data.data;
    },




        async readNotifications(id) {
       if (!this.isAuthenticated) return
       
      this.loading = true
      const sub = await NotificationService.updateNotification(id, {
        is_read:'true',
      }).then(response => {
        console.log(response)
        alert(response.data.data.videoId)
          this.loading = false
this.watchVideo(response.data.data.videoId)
      })
        .catch((err) => console.log(err))
      if (!sub) return

      
       },
watchVideo(videoId){
 this.$router.push(`/watch/${videoId}`)

},
    settingsMoal() {
      this.settingsDialog = true
    },
    async getSubscribedChannels() {
      const channels = await SubscriptionService.getSubscribedChannels(
        this.currentUser._id
      ).catch((err) => console.log(err))
      this.items[2].pages = channels.data.data
      this.channelLength = 3
    },
    moreChannels() {
      if (this.channelLength === 3)
        this.channelLength = this.items[2].pages.length
      else this.channelLength = 3
    },
    signOut() {
      this.$store.dispatch('signOut')
      // this.$router.push('/')
    },
getItemText(item) {
if(item.photoUrl != "")
{
    return item.userId.channelName;
}
else{
 return item.title;
}
},
          
  },



 components: {
    SettingsModal,
    UploadVideoModal
  },

  // beforeRouteLeave(to, from, next) {
  //   this.searchText = ''
  //   next()
  // },
  // beforeRouteUpdate(to, from, next) {
  //   if (!to.query['search-query'] === '') return
  //   this.searchText = to.query['search-query']
  //   next()
  // },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (!to.query['search-query'] === '') return
      vm.searchText = to.query['search-query']
      // vm.getSearchResults(to.query['search-query'])
    })
  },
  mounted() {
            this.getCategories();
             this.getNotification();

    // if (this.$route.query['search-query'])
    //   this.searchText = this.$route.query['search-query']

    if (this.currentUser) this.getSubscribedChannels()
    // this.user = this.$store.getters.currentUser
    // console.log(this.user)
    this.drawer = this.$vuetify.breakpoint.mdAndDown ? false : true
    // console.log(this.$route.name)
    this.drawer = this.$route.name === 'Watch' ? false : this.drawer
    this.drawer = this.$route.name === 'Home' ? true : false
  },
  created() {
    this.drawer = this.$route.name === 'Watch' ? false : this.drawer

    if (!this.isAuthenticated) {
      this.items[2].header = false
      this.items[0].pages.pop()
    }
  },
  watch: {
        model (val) {
          if (val != null) this.tab = 0
          else this.tab = null
        },
        search (val) {
console.log(val)
          this.isLoading = true
  SearchService.search(this.page, {
        text: val
      })
              .then(res => {
                console.log("finalvideos",res.data.data)
                this.videos = res.data.data
              })
              .catch(err => {
                console.log(err)
              })
              .finally(() => (this.isLoading = false)) 
        },
      },
  
}
</script>

<style lang="scss">
.v-list-item__avatar {
  justify-content: center !important;
}
#showBtn {
  .v-btn__content {
    justify-content: flex-start;

    i {
      margin-right: 30px;
    }
  }
}
#navbar {
  .active-item {
    .v-list-item__icon {
      color: red !important;
    }
  }
  .v-navigation-drawer__border {
    width: 0 !important;
  }

  .vuebar-element {
    height: 250px;
    width: 100%;
    max-width: 500px;
    background: #dfe9fe;
  }

  .vb > .vb-dragger {
    z-index: 5;
    width: 10px;
    right: 0;
  }

  .vb > .vb-dragger > .vb-dragger-styler {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transform: rotate3d(0, 0, 0, 0);
    transform: rotate3d(0, 0, 0, 0);
    -webkit-transition: background-color 100ms ease-out, margin 100ms ease-out,
      height 100ms ease-out;
    transition: background-color 100ms ease-out, margin 100ms ease-out,
      height 100ms ease-out;

    margin: 5px 5px 5px 0;
    border-radius: 20px;
    height: calc(100% - 10px);
    display: block;
  }

  .v-navigation-drawer__content:hover .vb > .vb-dragger > .vb-dragger-styler {
    width: 10px;
    background-color: #e0e0e0;
  }

  .vb.vb-scrolling-phantom > .vb-dragger > .vb-dragger-styler {
    background-color: rgba(48, 121, 244, 0.3);
    background-color: rgba(255, 255, 255, 0.3);
  }

  .vb > .vb-dragger:hover > .vb-dragger-styler {
    margin: 0px;
    width: 10px;
  }

  .vb.vb-dragging > .vb-dragger > .vb-dragger-styler {
    background-color: rgba(48, 121, 244, 0.5);
    margin: 0px;
    height: 100%;
  }

  .vb.vb-dragging-phantom > .vb-dragger > .vb-dragger-styler {
    background-color: rgba(48, 121, 244, 0.5);
  }
  .v-toolbar__content, .v-toolbar__extension{    border-bottom: 1px solid #f3f3f3;}
}
</style>
