import Api from '@/services/Api'
import Api2 from '@/services/Api2'

export default {
  getAll(data, params) {
    return Api().get(`videos/${data}`, {
      params
    })
  }, 
  getLast15daysVideos(data) {
    return Api().get(`getLast15daysVideos/${data}`)
  },

  getById(id) {
    return Api().get(`videos/${id}`)
  },
  uploadVideo(data, optional) {
    // return Api().post('videos', data, optional)
    return Api2().post('videos', data, optional)
  },
  updateVideo(id, data) {
    return Api().put(`videos/${id}`, data)
  },
  updateViews(id) {
    return Api().put(`videos/${id}/views`)
  },
  uploadThumbnail(id, data) {
    return Api().put(`videos/${id}/thumbnails`, data)
  },
  deleteById(id) {
    return Api().delete(`videos/${id}`)
  },
  contactus(data) {
    return Api().post(`contactus`,data)
  }
}

  

