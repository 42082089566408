import Api from '@/services/Api'

export default {
 
  createFeedback(data) {
    return Api().post('feedbacks', data)
  },
  deleteById(id) {
    return Api().delete(`feedbacks/${id}`)
  }
}
