<template>
  <v-app>
    <Header v-if="$route.name != 'SignIn' && $route.name != 'SignUp' "/>
    <router-view name="NavBar"></router-view>
    <v-content
      :class="{
        'content-bg': ![
          'SignIn',
          'SignUp',
          'Dashboard',
          'Video',
          'Detail'
        ].includes(this.$route.name)
          ? true
          : false
      }"
    >
      <router-view :key ="$route.path"></router-view>
    </v-content>
<Footer v-if="$route.name != 'SignIn' && $route.name != 'SignUp'"/>
<BlockUI :message="msg" v-if="isLoading" :url="loadingImage"></BlockUI>
  </v-app>
  
</template>

<script>
import loadingImage from '@/assets/images/ajax-loader-cs.gif'
import Vue from 'vue'
import BlockUI from 'vue-blockui'
 
Vue.use(BlockUI)

import Header from "./components/Header.vue";
import Footer from "./components/Footer.vue";
export default {
  name: 'app',
  data () {
    return {
      loadingImage:loadingImage,
      msg: '',
    }
    
  },
  computed:{
        isLoading(){
            return this.$store.state.is_ajax_loading
        }
},
    components: {
        Footer,
        Header,
        
    },
    methods: {
  toggleBodyClass(addRemoveClass, className) {
    const el = document.body;

    if (addRemoveClass === 'addClass') {
      el.classList.add(className);
    } else {
      el.classList.remove(className);
    }
  },
},
mounted() {
  this.toggleBodyClass('addClass', 'website_them_change');
},
destroyed() {
  this.toggleBodyClass('removeClass', 'website_them_change');
}
}
  

</script>

<style lang="scss">
body{font-family: 'Rubik', sans-serif!important;}
.content-bg {
  background-color: #f9f9f9;
}
.card {
  background: #f9f9f9 !important;
}
</style>
